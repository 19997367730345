"use client";
import React from "react";


import Blur from "../components/overlay/Blur";
import Color from "../components/overlay/Color";

import Footer from "../sections/Footer";
import HeroMainpage from "@/sections/heromainpage";

export default function Home() {

    return (
        <>
            <Blur />
            <Color />
            {/*<ScrollerMotion // Weird, stuff happening with this
                disabled={isMobile}
                spring={{ mass: 1, stiffness:800, bounce: 300, damping: 100 }}
            >*/}
            <main
                className="flex flex-col items-center justify-center bg-black"
            >
               
               <HeroMainpage certificate={1} />
<Footer />
            </main>
            {/*</ScrollerMotion>*/}
        </>
    );
}
